// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAP4GjOZoTsXs343teVih5f37OYp9woTs8",
    authDomain: "verylongunyte.firebaseapp.com",
    projectId: "verylongunyte",
    storageBucket: "verylongunyte.appspot.com",
    messagingSenderId: "783826770255",
    appId: "1:783826770255:web:2aed69161897bb25204d3a",
    measurementId: "G-NYWC9ME2SJ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
onAuthStateChanged(auth, user => { console.log(user); });
const analytics = getAnalytics(app);
export const firebaseFirestore = getFirestore(app);
// export const providerTwitter = new app.auth.TwitterAuthProvider();
export const fireauth = getAuth();