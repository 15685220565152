import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Slide } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import AdminTable from "./Admintable.tsx";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Tasks = () => {
  const hoge: string = "";
  const [byoga, setByoga] = React.useState("byoga");
  const [render, setRender] = React.useState("");
  const [admin, setAdmin] = React.useState(false);

  const auth = getAuth();
  const check = () => {
    onAuthStateChanged(auth, user => {
      if (user == null) {
        setAdmin(false)
      } else {
        // setCurrentAccount(user.auth.currentUser.displayName);
        // setIconImg(user.photoURL);
        if (user.auth.currentUser.displayName.match(/ベリロン公式/) || user.auth.currentUser.displayName.match(/Unyte/)) {
          // <blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/UnyteDAO?ref_src=twsrc%5Etfw">@UnyteDAO</a> proposal 自動リプライのテストです！ <a href="https://t.co/LAlb5Z1JPq">pic.twitter.com/LAlb5Z1JPq</a></p>&mdash; Uwaizumi.eth (@0xUYZ) <a href="https://twitter.com/0xUYZ/status/1591832226681204737?ref_src=twsrc%5Etfw">November 13, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          setAdmin(true)
        }
      }
    });
  };

  useEffect(() => {
    check();
  }, []);

  return <div>
    {admin && (
        <AdminTable byoga={byoga} setByoga={setByoga} render={render} setRender={setRender} />
    )}
    {!admin && (
        <Button style={{ minWidth: '135px', background: 'linear-gradient(45deg, #ff7f50,#ff1493)' }} color="inherit" onClick={() => { }}>ページを表示できません🥺</Button> 
    )}
  </div>;
};

export default Tasks;
