import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

import { useEffect, useState, useLayoutEffect } from 'react';

import { approve } from "./FirebaseAction.tsx";

// Firebase関係
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  getDoc
} from "firebase/firestore";
import { firebaseFirestore } from "../../data/Firebase";

import CheckWallet from "../../data/blockchain_actions/checkWallet";

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});


interface Column {
  id: 'Id' | 'Username' | 'Content' | 'Approved';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'Id', label: 'Id' },
  { id: 'Username', label: 'Username' },
  { id: 'Content', label: 'Content' },
  {
    id: 'Approved',
    label: 'Approved',
  }
];

interface Data {
  Id: string;
  Username: string;
  Content: string;
  Approved: string;
}

function createData(
  Id: string,
  Username: string,
  Content: string,
  Approved: string,
): Data {
  return { Id, Username, Content, Approved };
}

export default function StickyHeadTable(props) {
  // const rows = [createData('プロダクト機能考案', '🔥High🔥', '8/13 17:00', '0xUWYZ', 'Yoshi')];
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState("false");
  const [twi, setTwi] = React.useState({
    id: "",
    content: "",
    username: "",
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function readProposal() {
    const proposalsRef = collection(firebaseFirestore, "proposals");
    // ユーザーの所属チームを取得
    // docSnap.data().team.forEach(async (teamAr) => {
    var arr = [];
    await getDocs(query(proposalsRef)).then(async (snapshot) => {
      snapshot.forEach(async (doc: any) => {
        await arr.push(createData(doc.data().id, doc.data().username, doc.data().content, doc.data().approved))
      });
      // lastArr[teamAr] = arr
    })
    await setRows(arr);
    // }
    // await setRows(lastArr);

  };


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ユーザーのウォレットアドレス取得
  const [currentAccount, setCurrentAccount] = useState(null);
  useEffect(() => {
    connect();
  }, [success]);

  const connect = async () => {
    readProposal();
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {twi.username}さんの提案を承認しますか？
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ▼提案内容
              <br></br>
              {twi.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>いいえ</Button>
            <Button
              onClick={() => {
                handleClose();
                approve(twi.id).then((res) => {
                  setSuccess(res)
                });
              }}
              autoFocus>
              はい
            </Button>
          </DialogActions>
        </Dialog>
        <Toolbar sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 }
        }}>

          <Typography
            sx={{ flex: '1 1 100%' }}
            align='left'
            variant="h4"
            id="tableTitle"
            component="div"
          >
            みんなの提案
          </Typography>

        </Toolbar >
        <TableContainer sx={{ display: { xs: "none", sm: "flex" } }}>
          <Table stickyHeader aria-label="sticky table" >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.Id} style={{ textDecoration: 'none' }}>

                      <TableCell >
                        {row.Id}
                      </TableCell>
                      <TableCell >
                        {row.Username}
                      </TableCell>
                      <TableCell >
                        {row.Content}
                      </TableCell>
                      <TableCell >
                        {
                          row.Approved == "approved" ?
                            <Button style={{ minWidth: '135px', background: 'linear-gradient(45deg, #ff7f50,#ff1493)' }} color="inherit" onClick={() => { }}>承認済</Button> :
                            <Button style={{ minWidth: '135px' }} color="inherit" onClick={() => {
                              setTwi({
                                id: row.Id,
                                content: row.Content,
                                username: row.Username,
                              });
                              handleClickOpen();
                            }}>未承認</Button>
                        }
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" endIcon={<ArrowForwardIosIcon />} onClick={() => { window.open(`https://twitter.com/${row.Username}/status/${row.Id.toString()}`, '_blank') }} >
                          ツイートへ
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ display: { xs: "none", sm: "flex" } }}
        />
      </Paper>
      <Box sx={{ display: { xs: "flex", sm: "none" } }}>
        <Grid container>
          <Grid item xs={12}>
            {/* <ProposalCard></ProposalCard> */}
            {rows.map((row, i) => {
              return (
                <Box m={2} pt={3} style={{ textDecoration: 'none' }} >
                  <Card>
                    <CardContent>
                      {
                        row.Approved == "approved" ?
                          <Button style={{ minWidth: '135px', background: 'linear-gradient(45deg, #ff7f50,#ff1493)' }} color="inherit" onClick={() => { }}>承認済</Button> :
                          <Button style={{ minWidth: '135px' }} color="inherit" onClick={() => {
                            setTwi({
                              id: row.Id,
                              content: row.Content,
                              username: row.Username,
                            });
                            handleClickOpen();
                          }}>未承認</Button>
                      }
                      <Typography sx={{ mt: 1.5 }} variant="h6" component="div">
                        {row.Content}
                      </Typography>
                      <Typography sx={{ mt: 1.5 }} variant="body2">
                        {row.Username}
                      </Typography>
                      <Typography sx={{ mt: 1.5 }} color="text.secondary">
                        <Button color="inherit" onClick={() => { window.open(`https://twitter.com/${row.Username}/status/${row.Id.toString()}`, '_blank') }}>ツイートを見る</Button>
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              )
            })}
          </Grid>
        </Grid>
      </Box>

    </ThemeProvider >
  );
}
