import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Slide } from '@mui/material';
import Grid from '@mui/material/Grid';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

// リンクへのアクセス
import { Link } from "react-router-dom";

import { useEffect, useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
// Firebase関係
import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
} from "firebase/firestore";
import { firebaseFirestore } from "../../data/Firebase";

import { SubmitOutput } from "./FirebaseAction.tsx";
import { OutputAlert } from "./SnackBar.tsx";

import CheckWallet from "../../data/blockchain_actions/checkWallet";

import TaskOutputTable from "./TaskOutputTable.tsx";

const style = {
  // position: 'absolute' as 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: 600,
  color: 'white',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  background: 'linear-gradient(45deg, #ff7f50,#ff1493)',
  boxShadow: 24,
  p: 4,
};

const TaskDetail = () => {
  const { id } = useParams();
  const [byoga, setByoga] = React.useState('');

  // アラート用
  const [snack, setSnack] = React.useState('false');

  const [data, setData] = React.useState({
    assign: '',
    title: '',
    priority: '',
    description: '',
    createdBy: '',
    team: '',
  });

  async function readProposal() {
    const docRef: any = doc(firebaseFirestore, "proposals", id);
    const docSnap: any = await getDoc(docRef);

    if (docSnap.exists()) {
      const docSnap2 = await getDoc(doc(firebaseFirestore, "users", docSnap.data().createdBy));
      if (docSnap2.exists()) {
        const userName = docSnap2.data().name
        setData({
          title: docSnap.data().title,
          assign: docSnap.data().assign,
          priority: docSnap.data().priority,
          description: docSnap.data().description,
          createdBy: userName,
          team: docSnap.data().team,
        });
      } else {
        setData({
          title: docSnap.data().title,
          assign: docSnap.data().assign,
          priority: docSnap.data().priority,
          description: docSnap.data().description,
          createdBy: docSnap.data().createdBy,
          team: docSnap.data().team,
        });
      }
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    readProposal();
  }, [setByoga]);

  const [description, setDescription] = React.useState('');

  const handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const [output, setOutput] = React.useState('');

  const handleOutput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOutput(event.target.value);
  };

  const [currentAccount, setCurrentAccount] = useState(null);
  useEffect(() => {
    connect();
  }, []);

  const connect = async () => {
    CheckWallet().then(function (result) {
      const address = result;
      setCurrentAccount(address);
    });
  };

  return <div>
    <Button variant="contained" endIcon={<ArrowBackIosNewIcon />} component={Link} to={`/tasks`} >
      Back
    </Button>
    <OutputAlert snack={snack} setSnack={setSnack}></OutputAlert>
    <Stack spacing={2}>

      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {data.title}
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          assigned:{data.assign}
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          description:{data.description}
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          priority:{data.priority}
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          createdBy:{data.createdBy}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 10 }}>

        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          🚀Submit Your Output🚀
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <TextField id="fullWidth" label="Output Link" variant="outlined" value={output} onChange={handleOutput} />
          </FormControl>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <FormControl fullWidth margin="normal">
            <TextField multiline maxRows={5} id="fullWidth" label="Description" variant="outlined" value={description} onChange={handleDescription} />
          </FormControl>
        </Typography>
        <Button onClick={() => {
          const trying = SubmitOutput(description, output, currentAccount, id, data.team)
          if (trying == "success") {
            setSnack("output")
          }
          setByoga(output);
          setDescription("");
          setOutput("");
        }} style={{ background: 'linear-gradient(45deg, #ff7f50,#ff1493)' }} variant="contained" endIcon={<AddCircleIcon />} margin="normal">
          Submit
        </Button>
      </Box>
    </Stack >
    <TaskOutputTable taskid={id} byoga={byoga}></TaskOutputTable>

  </div >;
};

export default TaskDetail;
