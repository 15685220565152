import React, { useState, useEffect } from "react";

import { makeStyles } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';

import { AccountCircle, AccessAlarm, ThreeDRotation, AccountBalanceWallet } from '@mui/icons-material';
import { Tweet } from 'react-twitter-widgets'
import MyCommentTable from "./MyCommentTable.tsx";
import TeamComments from "./TeamComments.tsx";
import { countActivity, checkMyProposal,checkMyTask } from "./FirebaseAction.tsx";
import {
  useParams,
} from 'react-router-dom';
import { Link } from "react-router-dom";

import { getAuth, onAuthStateChanged } from 'firebase/auth';

const style = {
  // position: 'absolute' as 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: 600,
  color: 'white',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  background: 'linear-gradient(45deg, #ff7f50,#ff1493)',
  boxShadow: 24,
  // p: 2,
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
interface Data {
  Name: string;
  Address: string;
}

function createData(
  name: string,
  address: string,
) {
  return { name, address};
}



const Profile = (props) => {
  // テスト：子から親へ渡して再レンダリング
  const [byoga, setByoga] = useState("")
  const { id } = useParams();
  const [iconImg, setIconImg] = useState("");
  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);
  const [admin, setAdmin] = React.useState(false);
  // 各アクティビティの実績数を取得する状態変数
  const [activity, setActivity] = React.useState({
    "proposals": 0,
    "tasks": 0,
    "comments": 0,
  });
  // 現在の所有トークン数を取得する状態変数
  const [token, setToken] = React.useState({
    "UNYP": 0,
    "UNYT": 0,
    "UNYC": 0,
  });


  // ニックネーム変更用
  const [nickName, setNickName] = React.useState('');
  const handleNickName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNickName(event.target.value);
  };
  // ニックネーム編集画面判定
  const [editMode, setEditMode] = React.useState(false);

  const CenteredTabs = (props) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <div>
        <Paper className="">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {props.labels.map(label => <Tab label={label}></Tab>)}
          </Tabs>
        </Paper>

        {/* 追加 */}
        {props.children.map((child, index) =>
          <TabPanel value={value} index={index}>{child}</TabPanel>)
        }
      </div>
    );
  }

 const auth = getAuth();

  const connect = async() => {
      var acti = [];
    onAuthStateChanged(auth, user => {
      if (user == null) {
        setCurrentAccount("anonymous");
        setAdmin(false)
        readAccount("anonymous","anonymous")
      } else {
        setCurrentAccount(user.auth.currentUser.displayName);
        setCurrentUserName(user.auth.currentUser.reloadUserInfo.screenName);
        setIconImg(user.photoURL);
        if (user.auth.currentUser.displayName.match(/ベリロン公式/) || user.auth.currentUser.displayName.match(/Unyte/)) {
          setAdmin(true)
        }
        readAccount(user.auth.currentUser.displayName,user.auth.currentUser.reloadUserInfo.screenName)
      }
    })
    acti = await countActivity(id);
    setActivity({  
    "proposals": acti[0],
    "tasks": acti[1],
    "comments": acti[2],
  });
  }

  useEffect(() => {
    connect();
    }, []);
  

  const [rows, setRows] = React.useState([]);

  async function readAccount(name,address) {
    var arr: any = [];
    var arr2: any = [];
    // アドレスがDBにあるか探索
    await arr.push(createData(name, address))
    await setRows(arr);
  
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  function MyProposalTable() {
  const [tweetid, setTweetId] = React.useState([]);
  useEffect(() => {
    (async () => {
      const proposalTweets = await checkMyProposal(id);
      setTweetId(proposalTweets);
    })()
  }, []);
  return (
    <Box sx={{ mt: 2, mb: 2 }}  display="flex" justifyContent="center">
        <Grid container spacing={2} display="flex" justifyContent="center">
          {tweetid.map((proposal, index) => {
            return (
              <Grid item xs={12} md={4}>
                <Tweet tweetId={proposal} options={{ theme: "dark" }} />
              </Grid>
            )
          })}
        </Grid>
      </Box>
     );
}

function MyTaskTable() {
  const [taskid, setMyTaskId] = React.useState([]);
  useEffect(() => {
    (async () => {
      const proposalTweets = await checkMyTask(id);
      setMyTaskId(proposalTweets);
    })()
  }, []);
  return (
    <Box sx={{ mt: 2, mb: 2 }}  display="flex" justifyContent="center">
        <Grid container spacing={2} display="flex" justifyContent="center">
          {taskid.map((proposal, index) => {
            return (
              <Grid item xs={12} md={4}>
                <Tweet tweetId={proposal} options={{ theme: "dark" }} />
              </Grid>
            )
          })}
        </Grid>
      </Box>
     );
}


  return <div className="">
    <Box>
      < Box sx={{ display: { xs: "flex", sm: "flex" } }}>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              {rows.map((row, i) => {
                return (
                  <Grid item xs={12}>
                    <Box sx={{ mt: 2, mb: 2 }}  display="flex" justifyContent="center">
                      <Card sx={{ maxWidth: 600 }}>
                        <CardHeader
                          avatar={
                            <Avatar  src={iconImg}></Avatar>
                          }
                          action={
                            <IconButton aria-label="settings">
                              <MoreVertIcon />
                            </IconButton>
                          }
                          title="Shrimp and Chorizo Paella"
                          subheader="September 14, 2016"
                        />
                        <CardContent>
                                {row.name}<br />
                          <Typography sx={{ mt: 1.5 }} color="text.secondary">
                            address：{row.address}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                )
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box >
    <Divider></Divider>
    <CenteredTabs labels={[`Proposal(${activity.proposals})`, `Tasks(${activity.tasks})`, `comments(${activity.comments})`]}>
      <div>
        <MyProposalTable id={id} />
      </div>
      <div>
        <MyTaskTable id={id} />
      </div>
      <div>
        <MyCommentTable id={id} />
      </div>
    </CenteredTabs >
    <TeamComments id={id} byoga={byoga} />
  </div >;
};

export default Profile;
