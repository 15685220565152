import React from "react";

import TeamTable from "./TeamTable.tsx";
import TeamModal from "./TeamModal.tsx";

const Teams = () => {
  const hoge: string = "";
  // 再描画用
  const [byoga, setByoga] = React.useState("XYZ");
  console.log(byoga)
  const check = () => { }
  console.log("App");
  return <div>
    <TeamModal setByoga={setByoga}></TeamModal>
    <TeamTable byoga={byoga} setByoga={setByoga} />
  </div >;
};

export default Teams;
