// Firebase関係
import { onSnapshot } from "firebase/firestore";
import {
    doc,
    setDoc,
    collection,
    getDoc,
    getDocs,
    query,
    where,
    updateDoc,
    arrayUnion,
    orderBy,
} from "firebase/firestore";
import { firebaseFirestore } from "../../data/Firebase";

// スマコン関連のインポート
import { ethers } from "ethers"
import MyTokenContract from '../../contracts/MyToken.json';

// API関連
import axios from "axios";

export async function AddMember(name, address, role, teamId) {
    console.log(name, address, role, teamId)
    try {
        if (name != "" && address != "") {
            // アドレスが既に存在するか検索
            const docRef = doc(firebaseFirestore, "users", address.toLowerCase());
            const docSnap = await getDoc(docRef);
            console.log(docSnap.exists())
            if (docSnap.exists()) {
                // 参加済チームに今回のチームidを追加
                const docSna = await updateDoc(docRef, {
                    name: name,
                    role: role,
                    team: arrayUnion(teamId),
                });
                return ("success");
            } else {
                const usersRef = collection(firebaseFirestore, "users");
                const documentRef = setDoc(doc(usersRef, address.toLowerCase()), {
                    name: name,
                    address: address.toLowerCase(),
                    role: role,
                    team: [teamId],
                    id: address.toLowerCase(),
                    UNYP: 0,
                    UNYT: 0,
                    UNYC: 0,
                });
                return ("success");
            }
        } else {
            alert("空の値があります🥺");
            return ("fail");
        }
    } catch (error) {
        console.log(error)
        return ("fail");
    }
};

export function AddProposal(title, description, priority, assign, due, createdBy, teamid) {
    try {
        // 提案者がアクセスしているTeamidを取得
        if (title != "" && description != "" && priority != "" && assign != "" && due != "") {
            const proposalsRef = collection(firebaseFirestore, "proposals");
            const newDoc = doc(proposalsRef).id;
            const documentRef = setDoc(doc(proposalsRef, newDoc), {
                accepted: false,
                title: title,
                description: description,
                priority: priority,
                assign: assign,
                due: due,
                createdBy: createdBy,
                team: teamid,
                id: newDoc,
            });
            return ("success")
        } else {
            alert("空の値があります🥺");
            return ("fail")
        }
    } catch (error) {
        return ("fail")
    }
};

export async function AcceptProposal(id) {
    console.log(id)
    await updateDoc(doc(firebaseFirestore, "proposals", id.id), {
        accepted: true
    });
}

export function SubmitOutput(description, link, account, id, team) {
    try {
        if (description != "" && link != "" && id != "" && account != "") {
            const outputsRef = collection(firebaseFirestore, "outputs");
            const newDoc = doc(outputsRef).id;
            const documentRef = setDoc(doc(outputsRef, newDoc), {
                account: account,
                link: link,
                description: description,
                taskid: id,
                outputid: newDoc,
                team: team,
            });
            return ("success");
        } else {
            alert("空の値があります🥺");
            return ("fail");
        }
    } catch (error) {
        return ("fail");
    }
};

export async function AddTeam(name, description, address) {
    try {
        if (name != "" && address != "") {
            // チームを追加
            const teamsRef = collection(firebaseFirestore, "teams");
            const newDoc = doc(teamsRef).id;
            const documentRef = await setDoc(doc(teamsRef, newDoc), {
                name: name,
                description: description,
                owneraddress: address,
                id: newDoc,
            });
            // オーナーの参加チーム一覧に今回のチームを追加(id単位)
            // アドレスが既に存在するか検索
            const docRef = doc(firebaseFirestore, "users", address.toLowerCase());
            const docSnap = await getDoc(docRef);
            console.log(docSnap.exists())
            if (docSnap.exists()) {
                // 参加済チームに今回のチームidを追加
                const docSna = await updateDoc(docRef, {
                    team: arrayUnion(newDoc),
                });
            }
            return ("success")
        } else {
            alert("チーム名を入力してください🥺");
            return ("fail")
        }
    } catch (error) {
        return ("fail")
    }
};

export function SubmitComment(from, to, comment) {
    try {
        if (from != "" && to != "" && comment != "") {
            const commentsRef = collection(firebaseFirestore, "comments");
            const newDoc = doc(commentsRef).id;
            const documentRef = setDoc(doc(commentsRef, newDoc), {
                from: from,
                comment: comment,
                to: to,
                id: newDoc,
            });
            return ("success");
        } else {
            alert("コメントを入力してください🥺");
            return ("fail");
        }
    } catch (error) {
        return ("fail")
    }
};

export async function votingAction(proposalid, userid, vote) {
    const docRef = doc(firebaseFirestore, `proposals/${proposalid}/voting`, userid.toLowerCase());
    const docSnap = await getDoc(docRef);
    const votingsRef = collection(firebaseFirestore, `proposals/${proposalid}/voting`);
    if (docSnap.exists()) {
        await updateDoc(docRef, {
            vote: vote
        });
        // alert(`${vote}に投票内容を更新しました！`)
    } else {
        await setDoc(doc(votingsRef, userid.toLowerCase()), {
            id: userid.toLowerCase(),
            vote: vote
        });
        // alert(`${userid}：${vote}で投票しました！`)
    }
    // 提案が未承認の場合、チームメンバー数の2/3にfor数が達しているかを確認、達していた場合には承認済に変更し通知
    const proposalRef = await getDoc(doc(firebaseFirestore, `proposals/${proposalid}`));
    console.log(proposalRef.data())
    // チームID取得
    const teamid = proposalRef.data().team
    // ユーザーデータを参照、teamidに該当するチームに所属している人数を確認
    const usersRef = collection(firebaseFirestore, "users");
    const snapshot = await getDocs(usersRef);
    var memberamount = 0;
    snapshot.forEach(async (document) => {
        if (document.data().team.includes(teamid)) {
            memberamount += 1;
        } else {
        }
    });
    // forの意思表示をしている人数をカウント
    const snapshot2 = await getDocs(votingsRef);
    var foramount = 0;
    snapshot2.forEach(async (document) => {
        if (document.data().vote == "for") {
            foramount += 1;
        } else {
        }
    });
    // チームメンバー数の2/3にfor数が達しているかを確認、達していた場合には承認済に変更し通知
    console.log(foramount, memberamount)
    const border = memberamount * 2 / 3;
    if (foramount >= border) {
        console.log("過半数に達したので提案は承認されました", border)
        await updateDoc(doc(firebaseFirestore, `proposals/${proposalid}`), {
            accepted: true
        });
        alert("提案は承認されました")
        return ("success")
    } else {
        console.log("まだ過半数ではない")
        return ("success")
    }
}

export async function editNickName(id, name) {
    const docRef = doc(firebaseFirestore, "users", id.toLowerCase());
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        await updateDoc(docRef, {
            name: name,
        });
        alert(`名前が${name}に変更されました`)
    }
}

export async function countActivity(username) {
    const arr = [];
    const proposalsRef = collection(firebaseFirestore, "proposals");
    const snapshot = await getDocs(query(proposalsRef, where("username", "==", username)));

    arr.push(snapshot.size)
    const contributionsRef = collection(firebaseFirestore, "contributions");
    const snapshot1 = await getDocs(query(contributionsRef, where("username", "==", username)));

    arr.push(snapshot1.size)
    const commentsRef = collection(firebaseFirestore, "comments");
    const snapshot2 = await getDocs(query(commentsRef, where("username", "==", username)));

    arr.push(snapshot2.size)
    return (arr);
}

export async function countProposal(address) {
    // アドレスが所属するチームを特定、IDを配列に保存
    var teams = [];
    var ongoing = 0;
    var past = 0;
    const docRef = doc(firebaseFirestore, "users", address.toLowerCase());
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        // 参加済チームに今回のチームidを追加
        teams = docSnap.data().team;
        // 所属チームIDが含まれるProposalの数をカウント
        const proposalsRef = collection(firebaseFirestore, "proposals");
        const snapshot = await getDocs(proposalsRef);
        snapshot.forEach(async (document) => {
            if (teams.includes(document.data().team) && document.data().accepted == true) {
                past += 1;
            } else if (teams.includes(document.data().team) && document.data().accepted == false) {
                ongoing += 1;
            }
            else {
            }
        });
        return ([ongoing, past])
    } else {
        return ([0, 0])
    }
}

export async function claimToken(address, token, now, earned) {
    const amount = earned - now;
    console.log(address, token, now, earned, amount);

    var message: string = "";

    // トークン払い出し処理
    await mintToken(token, address, amount).then(async function (result) {
        // 成功後にFirebase処理
        if (result == "done") {
            // アドレスが既に存在するか検索
            const docRef = doc(firebaseFirestore, "users", address.toLowerCase());
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                if (token == "UNYP") {
                    await updateDoc(docRef, {
                        UNYP: docSnap.data().UNYP + amount,
                    });
                } else if (token == "UNYT") {
                    await updateDoc(docRef, {
                        UNYT: docSnap.data().UNYT + amount,
                    });
                } else if (token == "UNYC") {
                    await updateDoc(docRef, {
                        UNYC: docSnap.data().UNYC + amount,
                    });
                }
                message = "done";
            } else {
                console.log("アドレスが見つかりません");
                message = "error";
            }
        } else if (result == "error") {
            message = "error";
        }
    }
    )
    return (message)
}

async function mintToken(token, address, amount) {
    // 報酬額を自分のウォレットから払い出す
    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

    var contractAddress: string = ""

    if (token == "UNYP") {
        contractAddress = "0x61d885c94A3557D7608835A97875FEFa782f66c8"
    } else if (token == "UNYT") {
        contractAddress = "0xfFa6ae20314d870aaf2a093Fc71caCE957338C9A"
    } else if (token == "UNYC") {
        contractAddress = "0x3927a4880b9Cac6f0b2468833a1D745DFB00EE3f"
    }

    try {
        const tokenContract = new ethers.Contract(
            contractAddress,
            MyTokenContract.abi,
            signer
        )
        // ミント
        console.log(address, amount, contractAddress);
        const mintTxn = await tokenContract.mint(address, amount);
        await mintTxn.wait()
        return ("done");
    }
    catch (error) {
        console.log(error);
        return ("error");
    }
}

// 全てのproposalを取得
export async function checkProposal() {
    const proposalsRef = collection(firebaseFirestore, "proposals");
    const snapshot = await getDocs(query(proposalsRef,orderBy("id", "desc")));
    var arr = [];
    snapshot.forEach(async (document) => {
        arr.push(document.data().id)
    });
    return (arr)
}

// 自分が提案したproposalのみ取得
export async function checkMyProposal(username) {
    const proposalsRef = collection(firebaseFirestore, "proposals");
    const snapshot = await getDocs(query(proposalsRef, where("username", "==", username)));
    var arr = [];
    snapshot.forEach(async (document) => {
        arr.push(document.data().id)
    });
    return (arr)
}

export async function approve(id) {
    const docRef = doc(firebaseFirestore, "proposals", id);
    const docSnap = await getDoc(docRef);
    const docSna = await updateDoc(docRef, {
        approved: "approved",
    });
    return (id)
}

export async function checkTask() {
    const contributionsRef = collection(firebaseFirestore, "contributions");
    const snapshot = await getDocs(query(contributionsRef,orderBy("id", "desc")));
    var arr = [];
    snapshot.forEach(async (document) => {
        arr.push(document.data().id)
    });
    return (arr)
}

// 自分が提案したtaskのみ取得
export async function checkMyTask(username) {
    const contributionsRef = collection(firebaseFirestore, "contributions");
    const snapshot = await getDocs(query(contributionsRef, where("username", "==", username)));
    var arr = [];
    snapshot.forEach(async (document) => {
        arr.push(document.data().id)
    });
    return (arr)
}