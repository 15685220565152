import React, { useEffect, useState } from "react";
import { ethers } from "ethers";

// Firebase関係
import {
    doc,
    setDoc,
    collection,
    getDoc,
} from "firebase/firestore";
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { fireauth, app, providerTwitter } from "../../data/Firebase";
import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { typeAuthState, authState } from "../AuthState.tsx";
import { useSetRecoilState, SetterOrUpdater } from "recoil";

export async function Login() {
    const auth = getAuth();
    // const setAuth = useSetRecoilState(authState);
    const provider = new TwitterAuthProvider();
    // ログイン用関数
    var img = ""
    try {
        await signInWithPopup(fireauth, provider).then((res) => {
            if (res) {
                const tempUser = {
                    id: res.user.uid,
                    name: res.user.displayName,
                    email: res.user.email,
                    img: res.user.photoURL,
                };
                // setAuth(tempUser);
                // navigate("/");
                img = res.user.photoURL
                // console.log(res.user.photoURL.toString())
                console.log("ログインに成功しました")
                // return (res.user.photoURL)
            }
            // return ("成功！")
        });
        // console.log(img)
        return (img)
    } catch {
        console.log("ログインに失敗しました");
        return ("error")
    }
};

export async function Logout() {
    const auth = getAuth();
    // const navigate = useNavigate();
    const provider = new TwitterAuthProvider();
    // ログイン用関数

    try {
        await signOut(fireauth);
        // navigate("/login");
        console.log("ログアウトに成功しました");
    } catch {
        console.log("ログアウトに失敗しました");
    }

};

// export default Login;