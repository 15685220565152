import React, { useState, useEffect } from "react";
import axios from "axios";
// import InputForm from "./InputForm";
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Grid } from "@mui/material";
import { TwitterTweetEmbed } from 'react-twitter-embed'
import { checkTweet } from "./FirebaseAction.tsx";

type typeImageTableState = {
  images: typeImages;
  message: string;
  screen_name: string;
};

type typeImages = {
  url: string[];
  height: number[];
  source: string[];
  max_id: string;
};

class MainTable extends React.Component<{}, typeImageTableState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      images: {
        url: [],
        height: [],
        source: [],
        max_id: "",
      },
      message: "",
      screen_name: "",
    };
  }

  handleSubmit = (screen_name: string) => {
    twitterAPI(screen_name, this.state.images.max_id)
      .then((res) => {
        this.setIineImages(res);
      })
      .catch(() => {
        this.setState({
          message: "取得に失敗しました。データが空か、スクリーンネームが間違っているかもしれません。",
        });
      });
  }

  setIineImages = (results: any) => {
    this.setState({ images: results, message: "done" })
    console.log(this.state.images)
  };

  // const proposalTweets = await checkTweet();
  // console.log(proposalTweets)

  render() {
    return (
      <div>
        {/* <InputForm onSubmit={(screen_name: string) => this.handleSubmit(screen_name)} /> */}
        <Button color="inherit" onClick={() => this.handleSubmit("aaaa")}>Home</Button>
        <div className="box h-64 text-center m-5 p-4 ...">
          {this.state.message}
        </div>
        <Box display="flex" justifyContent="center">
          {/* <TwitterTweetEmbed tweetId={"1225902606607945738"} />
          <TwitterTweetEmbed tweetId={"1225902606607945738"} />
          <br></br>
          <TwitterTweetEmbed tweetId={"1225902606607945738"} />
          <TwitterTweetEmbed tweetId={"1225902606607945738"} /> */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TwitterTweetEmbed tweetId={"1588793057021157376"} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TwitterTweetEmbed tweetId={"1583295458914750465"} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TwitterTweetEmbed tweetId={"1588792590090276869"} />
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}
export default MainTable;

async function twitterAPI(screen_name: string, max_id: string) {
  // let endpoint = `${process.env.REACT_APP_API_ENDPOINT_URL}/fav?name=${screen_name}&maxid=${max_id}`
  // let endpoint = `https://wup7ioqvo2.execute-api.ap-northeast-1.amazonaws.com/poc/tweets?name=0xuwyz&maxid=5`
  // let endpoint = `https://6b9b7un5he.execute-api.ap-northeast-1.amazonaws.com/prod/mentioned-tweets`
  // let endpoint = `https://6b9b7un5he.execute-api.ap-northeast-1.amazonaws.com/prod`
  // ↓アクセス成功したエンドポイント
  // let endpoint = 'https://pb91rlykab.execute-api.us-east-1.amazonaws.com/production/yyy'
  // return new Promise((resolve, reject) => {
  //   axios.get(endpoint)
  //     .then((res) => {
  //       resolve(res.data);
  //       console.log(res.data)
  //       res.data.data.forEach((tweet) => {
  //         console.log(tweet.text)
  //         if (tweet.text.match(/ヤー！/)) {
  //           console.log("ヤー！")
  //         } else {
  //           console.log("ぱわー！")
  //         }
  //       })
  //     })
  //     .catch((err) => {
  //       reject(err);
  //     });
  // });
  // const proposalTweets = await checkTweet();
  console.log(proposalTweets)
}