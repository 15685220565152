import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
// リンクへのアクセス
import { Link } from "react-router-dom";

// Firebase関係
import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firebaseFirestore } from "../../data/Firebase";

// MUI
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
// ダークモード判定
import useMediaQuery from '@mui/material/useMediaQuery';

import AddMemberModal from "./AddMemberModal.tsx";
import CheckWallet from "../../data/blockchain_actions/checkWallet";
import { useEffect, useState, useLayoutEffect } from 'react';
// import { async } from './FirebaseAction';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

interface Data {
  address: string;
  name: string;
  role: string;
  id: string;
  team: array;
  UNYP: number;
  UNYT: number;
  UNYC: number;
}

function createData(
  address: string,
  name: string,
  role: string,
  id: string,
  team: array,
  UNYP: number,
  UNYT: number,
  UNYC: number,
): Data {

  return {
    address,
    name,
    role,
    id,
    team,
    UNYP,
    UNYT,
    UNYC,
  };
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Role',
  },
  {
    id: 'UNYP',
    numeric: false,
    disablePadding: false,
    label: 'UNYP',
  },
  {
    id: 'UNYT',
    numeric: false,
    disablePadding: false,
    label: 'UNYT',
  },
  {
    id: 'UNYC',
    numeric: false,
    disablePadding: false,
    label: 'UNYC',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <ThemeProvider theme={theme}>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
    </ThemeProvider>
  );
}

export default function EnhancedTable(props) {
  // ユーザーのウォレットアドレス取得
  const [currentAccount, setCurrentAccount] = useState(null);
  const [render, setRender] = React.useState("render");
  useEffect(() => {
    connect();
  }, [props.byoga, render]);

  const connect = async () => {
    CheckWallet().then(function (result) {
      const address = result;
      setCurrentAccount(address);
    });
  };
  // アクセスしたユーザーのアドレスから、チーム一覧を取得してコンソールに表示
  const readTeams = async (address) => {
    await getDoc(doc(firebaseFirestore, "users", address)).then((snapshot) => {
      const arr = [];
      snapshot.data().team.map(async (te, i) => {
        //　IDがteのチームを探す
        const docSnap = await getDoc(doc(firebaseFirestore, "teams", te));
        if (docSnap.exists()) {
          await arr.push({
            name: docSnap.data().name,
            id: docSnap.data().id,
          });
        } else {
        }
        await setTeam(arr);
      })
    });
  };

  useLayoutEffect(() => {
    (async () => {
      await readTeams(currentAccount);
    })()
  }, [currentAccount, props.byoga, render]);

  useLayoutEffect(() => {
    (async () => {
      await readTeams(currentAccount);
    })()
  }, [currentAccount, props.byoga, render]);

  const { teamName } = props;
  const { teamId } = props;

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('address');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [team, setTeam] = React.useState([]);

  // 配列に値をセットする形ではどう？rowsに合わせる
  const [rows, setRows] = React.useState([""]);

  async function readMember() {
    const usersRef = collection(firebaseFirestore, "users");
    var arr = [];
    await getDocs(query(usersRef)).then((snapshot) => {
      snapshot.forEach(async (doc: any) => {
        // コメントを文字列に保存
        await arr.push(createData(doc.data().address, doc.data().name, doc.data().role, doc.data().id, doc.data().team, doc.data().UNYP, doc.data().UNYT, doc.data().UNYC))
      });
    });
    await setRows(arr);
    // console.log(arr)
    // const arrrrr = arr.filter(ar => {
    //   return (ar.team.includes("iyGy9oy9CABT0H29DhWA"))
    // });
    // console.log(arrrrr)
  };

  useEffect(() => {
    readMember();
    console.log("再描画？1回目")
    console.log(render)
  }, [props.byoga, render]);

  // useEffect(() => {
  //   readMember();
  //   console.log("再描画？")
  //   console.log(render)
  // }, [props.byoga, render]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


  return (
    <Box sx={{ width: '100%' }}>
      {team.map((tea, index) => {
        const arrrrr = rows.filter(ar => {
          return (ar.team.includes(tea.id))
        });
        return (
          <Box>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                }}
              >
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  align='left'
                  variant="h4"
                  id="tableTitle"
                  component="div"
                >
                  Team {tea.name}
                </Typography>
                <Tooltip title="Add member">
                  <AddMemberModal teamId={tea.id} setRender={setRender} />
                </Tooltip>
              </Toolbar>
              <TableContainer sx={{ display: { xs: "none", sm: "flex" } }}>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={arrrrr.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(arrrrr, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        // チーム所属メンバーのみ表示
                        if (row.team.includes(tea.id)) {
                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.name}
                              selected={isItemSelected}
                              component={Link} to={`/profile/${row.id}`}
                              style={{ textDecoration: 'none' }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="normal"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell>{row.role}</TableCell>
                              <TableCell>{row.UNYP}</TableCell>
                              <TableCell>{row.UNYT}</TableCell>
                              <TableCell>{row.UNYC}</TableCell>
                              <TableCell align='right'>
                                <Button variant="contained" endIcon={<ArrowForwardIosIcon />} component={Link} to={`/profile/${row.id}`} >
                                  Comment
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={arrrrr.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ display: { xs: "none", sm: "flex" } }}
              />
            </Paper>
            {stableSort(arrrrr, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <Card sx={{ m: 2, mr: 2, display: { xs: "flex", sm: "none" } }} component={Link} to={`/profile/${row.id}`} style={{ textDecoration: 'none' }}>
                    <CardContent>
                      <Typography sx={{ mt: 1.5 }} variant="h5" component="div">
                        <Box>
                          {row.name}<br />
                        </Box>
                      </Typography>
                      <Typography sx={{ mt: 1.5 }} color="text.secondary">
                        address：{row.address}
                      </Typography>
                      <Typography sx={{ mt: 1.5 }} color="text.secondary">
                        Earned：
                      </Typography>
                      <Typography sx={{ mt: 1.5 }} variant="h6" component="div">
                        {row.UNYP} UNYP ：
                        {row.UNYT} UNYT ：
                        {row.UNYC} UNYC
                      </Typography>
                    </CardContent>
                    {/* <Button variant="contained" endIcon={<ArrowForwardIosIcon />} component={Link} to={`/profile/${row.id}`} >
                      Comment
                    </Button> */}
                  </Card>
                )
              })}
          </Box>
        )
      })}
    </Box>
  );
}
