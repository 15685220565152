import React, { useState, useEffect } from "react";
import axios from "axios";
// import InputForm from "./InputForm";
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer,Paper, List, ListItem, ListItemIcon, ListItemText, Grid } from "@mui/material";
import { Tweet } from 'react-twitter-widgets'
import { Mention } from 'react-twitter-widgets'
import { checkTask } from "./FirebaseAction.tsx";

type typeImageTableState = {
  images: typeImages;
  message: string;
  screen_name: string;
};

type typeImages = {
  url: string[];
  height: number[];
  source: string[];
  max_id: string;
};

export default function Proposals() {
  const [tweetid, setTweetId] = React.useState([]);
  useEffect(() => {
    (async () => {
      const proposalTweets = await checkTask();
      setTweetId(proposalTweets);
    })()
  }, []);

  return (
    <div>
      <Box sx={{ mt: 2, mb: 2 }} display="flex" justifyContent="center">
        <Grid container spacing={2} display="flex" justifyContent="center">
          <Grid item xs={12} md={4} display="flex" justifyContent="center">
            <Mention username="UnyteDAO" options={{ size: "large",text: "contribution"}} />
          </Grid>
        </Grid>
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Typography
            sx={{ flex: '1 1 100%' }}
            align='center'
            variant="h4"
            id="tableTitle"
            component="div"
          >
            ▼みんなの貢献
          </Typography>
      <Box display="flex" justifyContent="center">
        <Grid container spacing={2}>
          {tweetid.map((proposal, index) => {
            return (
              <Grid item xs={12} md={4}>
                <Tweet tweetId={proposal} options={{ theme: "dark" }} />
              </Grid>
            )
          })}
        </Grid>
      </Box>
      </Paper>
    </div>
  );
}