import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import CheckWallet from "../../../data/blockchain_actions/checkWallet";
import { Login, Logout } from "../../../data/blockchain_actions/login";
import Grid from '@mui/material/Grid';
import "./Header.css";

// リンクへのアクセス
import { Link } from "react-router-dom";

// twitterログイン関連
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fireauth } from "../../../data/Firebase";

import { authState } from "../../../data/AuthState.tsx";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const useStyles = makeStyles(() => ({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  mode: 'dark',
  root: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);
  const [selected, setSelected] = useState("proposals");
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen); // Drawer の開閉状態を反転
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // アイコン画像保存用
  const [iconImg, setIconImg] = useState("");

  useEffect(() => {
    // CheckWallet();
    check();
  }, []);

  // const connect = () => {
  //   // Login().then(function (result) {
  //   //   const address = result;
  //   //   setCurrentAccount(address);
  //   // });
  //   Login()
  // };

  const auth = getAuth();

  const check = () => {
    onAuthStateChanged(auth, user => {
      // console.log(user.auth.currentUser.reloadUserInfo.screenName)
      if (user == null) {
        setCurrentAccount("anonymous");
        setAdmin(false)
      } else {
        setCurrentAccount(user.auth.currentUser.displayName);
        setCurrentUserName(user.auth.currentUser.reloadUserInfo.screenName);
        setIconImg(user.photoURL);
        if (user.auth.currentUser.displayName.match(/ベリロン公式/) || user.auth.currentUser.displayName.match(/Unyte/)) {
          setAdmin(true)
        }
      }
    });
  };

  useEffect(() => {
    checkURL();
  }, [window.location.href]);

  const checkURL = async () => {
    // URLを取得し、該当するものにセット
    if (window.location.href.indexOf("/proposal") > 0) {
      setSelected("proposals")
    }
    else if (window.location.href.indexOf("/team") > 0) {
      setSelected("teams")
    }
    else if (window.location.href.indexOf("/profile") > 0) {
      setSelected("mypage")
    }
    else if (window.location.href.indexOf("/task") > 0) {
      setSelected("tasks")
    }
  };

  return (
    <div className={classes.toolbarWrapper}>
      <AppBar position="static">
        <Toolbar className="header">
          <Box
            component="img"
            justifyContent="left"
            sx={{
              height: 64,
            }}
            alt="Your logo."
            src={`../../../../icon_wide.png`}
          >
          </Box>
          <Grid container justifyContent="flex-end" sx={{ display: { xs: 'none', md: 'flex' } }}>
            {selected == "tasks" ?
              <Button style={{ minWidth: '135px', background: 'linear-gradient(45deg, #ff7f50,#ff1493)' }} color="inherit" component={Link} to="/tasks" onClick={() => { setSelected("tasks") }}>Contributions</Button> :
              <Button style={{ minWidth: '135px' }} color="inherit" component={Link} to="/tasks" onClick={() => { setSelected("tasks") }}>Contributions</Button>
            }
            {selected == "proposals" ?
              <Button style={{ minWidth: '135px', background: 'linear-gradient(45deg, #ff7f50,#ff1493)' }} color="inherit" component={Link} to="/proposals" onClick={() => { setSelected("proposals") }}>Proposals</Button> :
              <Button style={{ minWidth: '135px' }} color="inherit" component={Link} to="/proposals" onClick={() => { setSelected("proposals") }}>Proposals</Button>
            }
            {/* {selected == "teams" ?
              <Button style={{ minWidth: '135px', background: 'linear-gradient(45deg, #ff7f50,#ff1493)' }} color="inherit" component={Link} to="/teams" onClick={() => { setSelected("teams") }}>Teams</Button> :
              <Button style={{ minWidth: '135px' }} color="inherit" component={Link} to="/teams" onClick={() => { setSelected("teams") }}>Teams</Button>
            } */}
            {/* {selected == "mypage" ?
              currentAccount && (
                <Button style={{ minWidth: '135px', background: 'linear-gradient(45deg, #ff7f50,#ff1493)' }} color="inherit" component={Link} to={`/profile/${currentAccount}`} onClick={() => { setSelected("mypage") }}>
                  {currentAccount.slice(0, 5)}...{currentAccount.slice(-5)}
                </Button>) :
              currentAccount && (
                <Button style={{ minWidth: '135px' }} color="inherit" component={Link} to={`/profile/${currentAccount}`} onClick={() => { setSelected("mypage") }}>
                  {currentAccount.slice(0, 5)}...{currentAccount.slice(-5)}
                </Button>)
            } */}
            {/* {selected == "mypage" ?
              !currentAccount && (
                // <Button style={{ minWidth: '135px' }} color="inherit" onClick={() => { connect(); setSelected("mypage") }}>
                // <Button style={{ minWidth: '135px' }} color="inherit" onClick={() => { connect(); }}>
                //   Login
                // </Button>
              ) :
              !currentAccount && (
                // <Button style={{ minWidth: '135px' }} color="inherit" onClick={() => { connect(); setSelected("mypage") }}>
                // <Button style={{ minWidth: '135px' }} color="inherit" onClick={() => { connect(); }}>
                //   Login
                // </Button>
              )} */}
            {currentAccount != "anonymous" ?
              (<div>
                {/* <Typography>
                  <Avatar alt="Remy Sharp" src="https://pbs.twimg.com/profile_images/1532011932688846848/vnzRGVAO_normal.jpg" />
                </Typography> */}
                <Box style={{ minWidth: '135px', textDecoration: 'none' }} sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ mr: 2, ml: 2 }}
                      style={{ textDecoration: 'none' }}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <Avatar sx={{ width: 32, height: 32 }} src={iconImg}></Avatar>{currentAccount}
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: 10,
                        mr: 10,
                        pr: 10,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {/* <MenuItem>
                    <Avatar /> Profile
                  </MenuItem>
                  <MenuItem>
                    <Avatar /> My account
                  </MenuItem> */}
                  {admin && (
                    <MenuItem component={Link} to="/admin" onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <AdminPanelSettingsIcon fontSize="small" />
                    </ListItemIcon>
                    管理画面へ
                  </MenuItem>
                  )} 
                  <Divider />
                    <MenuItem component={Link} to={`/profile/${currentUserName}`}  onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    マイページ
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => { Logout() }}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
                {/* <Button style={{ minWidth: '135px' }} color="inherit" onClick={() => { Logout() }}>
                  Logout
                </Button> */}
              </div>
              )
              :
              (<Button style={{ minWidth: '135px' }} color="inherit" onClick={() => {
                Login().then(function (result) {
                  const img = result;
                  console.log(result);
                  setIconImg(result)
                  if (result.match(/ツボツボ/)) {
                    setAdmin(true)
                  }
                });
              }}>
                Login
              </Button>)}
          </Grid>
          <Box display="flex" justifyContent="flex-end" sx={{ display: { xs: 'flex', md: 'none' } }} >
            <IconButton
              onClick={handleDrawerToggle}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Drawer
            variant="temporary"
            open={drawerOpen}
            onClose={handleDrawerToggle}
          >
            <List >
              <ListItem>
                <HomeIcon />
                <Button color="inherit" component={Link} to="/" onClick={handleDrawerToggle}>Home</Button>
              </ListItem>
              <ListItem>
                <InfoIcon />
                <Button color="inherit" component={Link} to="/tasks" onClick={handleDrawerToggle}>Tasks</Button>
              </ListItem>
              <ListItem>
                <InfoIcon />
                <Button color="inherit" component={Link} to="/proposals" onClick={handleDrawerToggle}>Proposals</Button>
              </ListItem>
              {/* <ListItem>
                <InfoIcon />
                <Button color="inherit" component={Link} to="/teams" onClick={handleDrawerToggle}>Team</Button>
              </ListItem> */}
              <ListItem>
                {currentAccount != "anonymous" ?
                  (<div>
                    <Box  >
                      <Tooltip title="Account settings">
                        <IconButton
                          onClick={handleClick}
                          size="small"
                          sx={{ mr: 2, ml: 2 }}
                          style={{ textDecoration: 'none' }}
                          aria-controls={open ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                        >
                          <Avatar sx={{ width: 32, height: 32 }} src={iconImg}></Avatar>{currentAccount}
                        </IconButton>
                      </Tooltip>
                    </Box>
                    
                    {/* <Button style={{ minWidth: '135px' }} color="inherit" onClick={() => { Logout() }}>
                  Logout
                </Button> */}
                  </div>
                  )
                  :
                  (<Button style={{ minWidth: '135px' }} color="inherit" onClick={() => {
                    Login().then(function (result) {
                      const img = result;
                      console.log(result);
                      setIconImg(result)
                    });
                  }}>
                    Login
                  </Button>)}
              </ListItem>
              {/* <ListItem>
                <InfoIcon />
                {currentAccount && (
                  <Button color="inherit" component={Link} to={`/profile/${currentAccount}`} onClick={handleDrawerToggle}>
                    {currentAccount.slice(0, 6)}...{currentAccount.slice(-6)}
                  </Button>
                )}
                {!currentAccount && (
                  <Button color="inherit" onClick={() => connect()}>
                    Login
                  </Button>
                )}
              </ListItem> */}
            </List>
          </Drawer>
        </Toolbar>
      </AppBar>
    </div >
  );
}
